<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="flex justify-content-between mb-4 table-label-search">
          <h3>Last orders</h3>
        </div>

        <DataTable
            :value="orders" :lazy="true"
            :rows="pagination?.per_page ?? 25"
            ref="dt"
            dataKey="order_id"
            :first="pagination?.from-1"
            :totalRecords="pagination?.total"
            :loading="loading"
            @page="onPage($event)"
            @sort="onSort($event)"
            v-model:expandedRows="expandedRows"
            responsiveLayout="scroll"
            :rowsPerPageOptions="[10,25,50]"
            :sortField="sort"
            :sortOrder="order"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
          <template #empty>
            Not found.
          </template>
          <Column :expander="true" :headerStyle="{'width': '3rem'}" />
          <!-- Order ID -->
          <Column field="order_id" header="ID" :sortable="true" />

          <!-- Company name -->
          <Column field="company" header="Company" :showFilterMenu="false" :sortable="true" v-if="showCompanyColumn">
            <template #body="{data}">
              <span class="image-text">{{data.company}}</span>
            </template>
          </Column>

          <!-- User name -->
          <Column field="user_name" header="User" :sortable="true" />

          <!-- Tool name -->
          <Column field="tool_name" header="Tool" :sortable="true" :showFilterMenu="false">
            <template #body="{data}">
              <span>
                {{ data.tool_name }}
              </span>
            </template>
          </Column>

          <!-- IMEI -->
          <Column field="imei" header="IMEI" />

          <!-- Amount -->
          <Column field="amount" header="Amount" :sortable="true" />

          <!-- Status -->
          <Column field="status" header="Status" :sortable="true" :body-style="{ width: '130px'}">
            <template #body="{data}">
              <span :class="'product-badge status-' + statusText(data.status).name">
                <span v-if="statusText(data.status).name.toUpperCase() === 'IN PROGRESS' && data.category === 'CUSTOM TOOLS'">CUSTOM TOOL</span>
                <span v-else>{{ statusText(data.status).name.toUpperCase() }}</span>
              </span>
            </template>
          </Column>

          <!-- Created at -->
          <Column field="created_at" header="Created" :sortable="true" />

          <!-- Updated at -->
          <Column field="updated_at" header="Updated" :sortable="true" />

          <template #expansion="slotProps">
            <div v-if="slotProps.data.comment">Comment: {{ slotProps.data.comment }}</div>
            <div v-html="slotProps.data.result"></div>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import toastParamBuilder from "@/builders/ToastParamBuilder";
import dataTable from "@/mixins/dataTable";
import store from "@store";
import user from '@/models/User';
import {FilterMatchMode} from "primevue/api";
import orderStatuses from '@models/OrderStatus';
import companyResource from "@http/CompanyResource";
import ToolResource from "../../http/ToolResource";

export default {
  mixins: [dataTable],
  props: {
    type: null
  },
  async mounted() {
    ToolResource.visibleAndPublished()
        .then(({data}) => {
          this.tools = data;
        });
    if (this.showCompanyColumn) {
      companyResource.companiesWhichHasOrders()
          .then(({data}) => {
            this.companies = data;
            if (this.$route.query?.company) {
              this.filters.company.value = data.filter((e) => {
                return this.$route.query?.company.includes(e.id.toString());
              }).map(e => e.id);
            }
          });
    }
    await store.dispatch('order/getOrders');
  },

  data() {
    return {
      companies: [],
      tools: [],
      selectedTool: [],
      loading: false,
      expandedRows: [],
      filters: {
        status: {value: null, matchMode: FilterMatchMode.EQUALS},
        company: {value: null, matchMode: FilterMatchMode.EQUALS},
        tool: {value: null, matchMode: FilterMatchMode.EQUALS},
      },
      refreshInterval: null,
    }
  },

  computed: {
    ...mapGetters(
        'order', [
          'orders',
          'pagination',
        ]
    ),

    statuses() {
      return orderStatuses;
    },

    statusText() {
      return status => orderStatuses.find(i => i.id === status);
    },

    showCompanyColumn() {
      return store.getters['auth/getRole'] === user.ROLE_SUPER_ADMIN;
    },
  },

  async beforeRouteEnter(to, from, next) {
    try {
      await store.dispatch('order/getOrders', to.query);
      next((vm) => {

        if (vm.$route.query?.status) {
          vm.filters.status.value = vm.statuses.filter((e) => {
            return vm.$route.query?.status.includes(e.id.toString());
          }).map(e => e.id);
        }

        if (vm.showCompanyColumn) {
          companyResource.companiesWhichHasOrders()
              .then(({data}) => {
                vm.companies = data;
                if (vm.$route.query?.company) {
                  vm.filters.company.value = data.filter((e) => {
                    return vm.$route.query?.company.includes(e.id.toString());
                  }).map(e => e.id);
                }
              });
        }

        vm.loading = false;
      });
    } catch (error) {
      next((vm) => {
        vm.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
      });
    }
  },
  async beforeRouteUpdate(to) {
    await store.dispatch('order/getOrders', to.query)
        .finally(() => {
          this.loading = false;
        });
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/demo/badges.scss';
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
</style>
