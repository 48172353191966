import orderResource from "@/http/OrderResource";

export default {
  namespaced: true,
  state: {
    orders: null,
    pagination: null,
  },
  getters: {
    orders(state) {
      return state.orders;
    },
    pagination(state) {
      return state.pagination;
    },
  },
  mutations: {
    setOrders(state, payload) {
      state.orders = payload;
    },
    setPagination(state, payload) {
      state.pagination = payload;
    },
  },
  actions: {
    getOrders({commit}, params) {
      return orderResource.all(params)
        .then(response => {
          commit('setOrders', response.data?.data);
          commit('setPagination', response.data?.meta);
          return Promise.resolve(response);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    createOrder(_, payload) {
      return orderResource.create(payload)
        .then(response => {
          return Promise.resolve(response);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    completeOrder(_, payload) {
      return orderResource.complete(payload)
        .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        return Promise.reject(error);
      });
    },
    inProgressOrder(_, payload) {
      return orderResource.inProgress(payload)
    },
    downloadTable(_, params) {
      return orderResource.downloadTable(params)
        .then(response => {
          return Promise.resolve(response);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
  },
}
