<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="flex justify-content-between mb-4 table-label-search">
          <h3>Orders</h3>
          <div>
            <create-order style=""/>
            <Button
                label="Download in XLS"
                class="btn btn-primary"
                style="margin-right: 10px; margin-top: 5px;"
                icon="pi pi-download"
                @click="downloadTable"
            />

            <span class="p-input-icon-left mt-2">
              <i class="pi pi-search" />
              <InputText v-model="search" placeholder="Keyword Search" @input="onSearch" />
            </span>
          </div>
        </div>

        <DataTable
            :value="orders" :lazy="true"
            :paginator="true"
            :rows="pagination?.per_page ?? 25"
            ref="dt"
            dataKey="order_id"
            :first="pagination?.from-1"
            :totalRecords="pagination?.total"
            :loading="loading"
            @page="onPage($event)"
            @sort="onSort($event)"
            v-model:expandedRows="expandedRows"
            responsiveLayout="scroll"
            :rowsPerPageOptions="[10,25,50]"
            :sortField="sort"
            :sortOrder="order"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            :globalFilterFields="['status', 'company', 'tool_name']"
            v-model:filters="filters"
            filterDisplay="row"
            @filter="onFilter($event, ['status', 'company', 'tool_name'])"
        >
          <template #empty>
            Not found.
          </template>
          <Column :expander="true" :headerStyle="{'width': '3rem'}" />
          <!-- Order ID -->
          <Column field="order_id" header="ID" :sortable="true" />

          <!-- Company name -->
          <Column field="company" header="Company" :showFilterMenu="false" :sortable="true" v-if="showCompanyColumn">
            <template #body="{data}">
              <span class="image-text">{{data.company}}</span>
            </template>
            <template #filter="{filterModel,filterCallback}">
              <MultiSelect
                  v-model="filterModel.value" @change="filterCallback()" :options="companies"
                  optionLabel="name" optionValue="id" placeholder="Any" class="p-column-filter"
                  :showClear="true"
              >
                <template #option="slotProps">
                  <div class="p-multiselect-representative-option">
                    <span class="image-text">{{slotProps.option.name}}</span>
                  </div>
                </template>
              </MultiSelect>
            </template>
          </Column>

          <!-- User name -->
          <Column field="user_name" header="User" :sortable="true" />

          <!-- Tool name -->
          <Column field="tool_name" header="Tool" :sortable="true" :showFilterMenu="false" :showClearButton="false">
            <template #body="{data}">
              <span>
                {{ data.tool_name }}
              </span>
            </template>
            <template #filter="{filterModel, filterCallback}">
              <Dropdown
                  v-model="filterModel.value" @change="filterCallback()" :options="tools"
                  optionLabel="name" optionValue="id" placeholder="Any" class="p-column-filter max-w-20rem"
                  :showClear="true"
              >
                <template #option="slotProps">
                  <div class="p-multiselect-representative-option">
                    <span>
                      {{slotProps.option.tool_name}}
                    </span>
                  </div>
                </template>
              </Dropdown>
            </template>
          </Column>

          <!-- IMEI -->
          <Column field="imei" header="IMEI" />

          <!-- Amount -->
          <Column field="amount" header="Amount" :sortable="true" />

          <!-- Status -->
          <Column field="status" header="Status" :showFilterMenu="false" :sortable="true" :body-style="{ width: '130px'}">
            <template #body="{data}">
              <span :class="'product-badge status-' + statusText(data.status).name">
                {{ statusText(data.status).name.toUpperCase() }}
              </span>
            </template>
            <template #filter="{filterModel,filterCallback}">
              <MultiSelect
                  v-model="filterModel.value" @change="filterCallback()" :options="statuses"
                  optionLabel="name" optionValue="id" placeholder="Any" class="p-column-filter"
                  :showClear="true"
              >
                <template #option="slotProps">
                  <div class="p-multiselect-representative-option">
                    <span :class="'product-badge status-' + slotProps.option.name">
                      {{slotProps.option.name}}
                    </span>
                  </div>
                </template>
              </MultiSelect>
            </template>
          </Column>

          <!-- Created at -->
          <Column field="created_at" header="Created" :sortable="true" />

          <!-- Updated at -->
          <Column field="updated_at" header="Updated" :sortable="true" />

          <template #expansion="slotProps">
            <h5 class="mb-2">Order information</h5>
            <div v-if="slotProps.data.comment">Comment: {{ slotProps.data.comment }}</div>
            <div class="mt-2" v-if="isAdmin && slotProps.data.status === 3 && slotProps.data.api_id === 0">
              <p>IMEI: {{ slotProps.data.imei }}</p>
              <label for="result">Result</label>
              <Editor id="result" v-model="formData.result" editorStyle="height: 220px" class="mt-1"/>
              <div class="flex" style="">
                <Button label="Complete order" class="mt-3" @click="completeOrder(slotProps.data.order_id)"></Button>
                <Button label="Reject order" severity="danger" class="mt-3" style="margin-left: 20px;" @click="completeOrder(slotProps.data.order_id, false)"></Button>
              </div>
            </div>
            <div v-else>
              <div v-html="slotProps.data.result"></div>
              <div v-if="isAdmin && (slotProps.data.status === 1 || slotProps.data.status === 2) && slotProps.data.api_id === 0">
                <Button label="Return in progress" style="" severity="warning" class="mt-3" @click="inProgressOrder(slotProps.data.order_id)"></Button>
              </div>
            </div>
          </template>
        </DataTable>
      </div>
    </div>
    <ConfirmDialog></ConfirmDialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import toastParamBuilder from "@/builders/ToastParamBuilder";
import dataTable from "@/mixins/dataTable";
import store from "@store";
import user from '@/models/User';
import CreateOrder from "@/pages/app/Orders/CreateOrder";
import {FilterMatchMode} from "primevue/api";
import orderStatuses from '@models/OrderStatus';
import companyResource from "@http/CompanyResource";
import ToolResource from "../../../http/ToolResource";

export default {
  mixins: [dataTable],
  props: {
    type: null
  },
  async mounted() {
    ToolResource.visibleAndPublished()
        .then(({data}) => {
          this.tools = data;
        });
    if (this.showCompanyColumn) {
      companyResource.companiesWhichHasOrders()
        .then(({data}) => {
          this.companies = data;
          if (this.$route.query?.company) {
            this.filters.company.value = data.filter((e) => {
              return this.$route.query?.company.includes(e.id.toString());
            }).map(e => e.id);
          }
        });
    }

    this.startOrderRefresh();
  },
  data() {
    return {
      formData: {},
      companies: [],
      tools: [],
      selectedTool: [],
      loading: false,
      expandedRows: [],
      search: null,
      filters: {
        status: {value: null, matchMode: FilterMatchMode.EQUALS},
        company: {value: null, matchMode: FilterMatchMode.EQUALS},
        tool_name: {value: null, matchMode: FilterMatchMode.EQUALS},
      },
      refreshInterval: 10000,
      orderRefreshIntervalId: null,
    }
  },

  components: {
    CreateOrder,
  },
  methods: {
    startOrderRefresh() {
      this.orderRefreshIntervalId = setInterval(() => {
        this.updateOrderData();
      }, this.refreshInterval);
    },

    stopOrderRefresh() {
      clearInterval(this.orderRefreshIntervalId);
    },

    updateOrderData() {
      this.loading = true;

      const sortField = this.$route.query.sort;
      const sortOrder = this.$route.query.order === 'asc' ? 'asc' : 'desc';

      const query = {
        search: this.search,
        status: this.filters.status.value,
        company: this.filters.company.value,
        tool_name: this.filters.tool_name.value,
        sort: sortField,
        order: sortOrder,
        page: this.pagination.page,
        per_page: this.pagination.per_page,
      };

      store.dispatch('order/getOrders', query).finally(() => {
        this.loading = false;
      });
    },

    completeOrder(id, completed = true) {
      this.$confirm.require({
        message: 'Are you sure you want to complete the order?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.loading = true;
          let status = completed ? 1 : 2;
          store.dispatch('order/completeOrder', { id: id, result: this.formData.result, status: status })
            .then(async (res) => {
              if (res.data.success) {
                await store.dispatch('order/getOrders');
                await store.dispatch('company/company');
                this.$toast.add({severity: 'success', summary: 'Success', detail: 'Order completed', life: 3000});
              } else {
                this.$toast.add({severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000});
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
              this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        }
      });
    },
    inProgressOrder(id) {
      this.$confirm.require({
        message: 'Are you sure you want return "In progress" the order?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.loading = true;
          store.dispatch('order/inProgressOrder', {id: id, result: '', status: 3})
              .then(async (res) => {
                if (res.data.success) {
                  await store.dispatch('order/getOrders');
                  await store.dispatch('company/company');
                  this.$toast.add({severity: 'success', summary: 'Success', detail: 'Order In Progress', life: 3000});
                } else {
                  this.$toast.add({severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000});
                }
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
                this.$toast.add({severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000});
              });
        },
        reject: () => {
          this.$toast.add({severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000});
        }
      });
    },
    downloadTable() {
      const params = this.$route.query;
      this.$store.dispatch('order/downloadTable', params, {responseType: 'blob'})
          .then(response => {

            // date and time
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');
            const dateAndTime = `${year}.${month}.${day}_${hours}.${minutes}.${seconds}`;
            const fileName = `orders_${dateAndTime}.xlsx`;

            const blob = new Blob([response.data], {type: response.headers['content-type'] || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
          })
          .catch(error => {
            console.error('Error loading table:', error);
          });
    }
  },
  computed: {
    isAdmin() {
      return [user.ROLE_SUPER_ADMIN].includes(store.getters['auth/getRole']);
    },
    ...mapGetters(
        'order', [
          'orders',
          'pagination',
        ]
    ),

    statuses() {
      return orderStatuses;
    },

    statusText() {
      return status => orderStatuses.find(i => i.id === status);
    },

    showCompanyColumn() {
      return store.getters['auth/getRole'] === user.ROLE_SUPER_ADMIN;
    },
  },

  async beforeRouteEnter(to, from, next) {
    try {
      await store.dispatch('order/getOrders', to.query);
      next((vm) => {

        if (vm.$route.query?.status) {
          vm.filters.status.value = vm.statuses.filter((e) => {
            return vm.$route.query?.status.includes(e.id.toString());
          }).map(e => e.id);
        }

        if (vm.showCompanyColumn) {
          companyResource.companiesWhichHasOrders()
              .then(({data}) => {
                vm.companies = data;
                if (vm.$route.query?.company) {
                  vm.filters.company.value = data.filter((e) => {
                    return vm.$route.query?.company.includes(e.id.toString());
                  }).map(e => e.id);
                }
              });
        }

        vm.loading = false;
      });
    } catch (error) {
      next((vm) => {
        vm.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
      });
    }
  },
  async beforeRouteUpdate(to) {
    await store.dispatch('order/getOrders', to.query)
        .finally(() => {
          this.loading = false;
        });
  },
  beforeRouteLeave() {
    this.stopOrderRefresh();
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/demo/badges.scss';
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
</style>
