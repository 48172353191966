<template>
  <div class="xl:col-6 col-12">
    <div class="card">
      <h5 class="text-center">Personal Profile</h5>
      <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
        <!-- First name -->
        <div class="field lpmb-2">
          <div class="p-float-label">
            <InputText id="first_name" v-model="v$.form.first_name.$model" :class="{'p-invalid':v$.form.first_name.$invalid && submitted}"/>
            <label for="first_name" :class="{'p-error':v$.form.first_name.$invalid && submitted}">First Name*</label>
          </div>

          <small
            v-if="(v$.form.first_name.$invalid && submitted) || v$.form.first_name.$pending.$response"
            class="p-error">{{ v$.form.first_name.required.$message.replace('Value', 'First Name') }}
          </small>
        </div>

        <!-- Last name -->
        <div class="field lpmb-2">
          <div class="p-float-label">
            <InputText id="last_name" v-model="v$.form.last_name.$model" :class="{'p-invalid':v$.form.last_name.$invalid && submitted}"/>
            <label for="last_name" :class="{'p-error':v$.form.last_name.$invalid && submitted}">Last Name*</label>
          </div>

          <small
            v-if="(v$.form.last_name.$invalid && submitted) || v$.form.last_name.$pending.$response"
            class="p-error">{{ v$.form.last_name.required.$message.replace('Value', 'Last Name') }}
          </small>
        </div>

        <!-- Email -->
        <div class="field lpmb-2">
          <div class="p-float-label p-input-icon-right">
            <i class="pi pi-envelope"/>
            <InputText id="email" v-model="form.email" class="disabled" disabled />
            <label for="email">Email</label>
          </div>
        </div>

        <!-- Password -->
        <div class="field lpmb-2">
          <div class="p-float-label">
            <Password id="password" v-model="v$.form.password.$model" :class="{'p-invalid':v$.form.password.$invalid && submitted}" toggleMask>
              <template #header>
                <h6>Pick a password</h6>
              </template>
              <template #footer="sp">
                {{ sp.level }}
                <Divider/>
                <p class="mt-2">Suggestions</p>
                <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                  <li>At least one lowercase</li>
                  <li>At least one uppercase</li>
                  <li>At least one numeric</li>
                  <li>Minimum 8 characters</li>
                </ul>
              </template>
            </Password>
            <label for="password" :class="{'p-error':v$.form.password.$invalid && submitted}">Password</label>
          </div>

          <span v-if="v$.form.password.$error && submitted">
              <span v-for="(error, index) of v$.form.password.$errors" :key="index">
                <small class="p-error">{{ error.$message.replace('Value', 'Password') }}</small>
              </span>
            </span>
          <small
            v-else-if="(v$.form.password.$invalid && submitted) || v$.form.password.$pending.$response"
            class="p-error">{{ v$.form.password.required.$message.replace('Value', 'Password') }}
          </small>
        </div>

        <!-- Confirm Password -->
        <div class="field lpmb-2">
          <div class="p-float-label">
            <Password id="password_confirmation" v-model="v$.form.password_confirmation.$model" :class="{'p-invalid':v$.form.password_confirmation.$invalid && submitted}" toggleMask />
            <label for="password_confirmation" :class="{'p-error':v$.form.password_confirmation.$invalid && submitted}">Confirm Password</label>
          </div>

          <small
            v-if="(v$.form.password_confirmation.$invalid && submitted) || v$.form.password_confirmation.$pending.$response"
            class="p-error">{{ v$.form.password_confirmation.sameAsPassword.$message.replace('Value', 'Confirm Password') }}
          </small>
        </div>

        <!-- Phone -->
        <div class="field lpmb-2">
          <div class="p-float-label">
            <InputText id="phone" v-model="v$.form.phone.$model" :class="{'p-invalid':v$.form.phone.$invalid && submitted}"/>
            <label for="phone" :class="{'p-error':v$.form.phone.$invalid && submitted}">Phone*</label>
          </div>

          <small
            v-if="(v$.form.phone.$invalid && submitted) || v$.form.phone.$pending.$response"
            class="p-error">{{ v$.form.phone.required.$message.replace('Value', 'Phone') }}
          </small>
        </div>

        <!-- Notification -->
        <div class="flex align-items-center mb-4">
          <input type="checkbox" v-model="form.is_notification" />
          <span class="ml-2">Receive notifications via email</span>
        </div>

        <Button type="submit" label="Submit" class="mt-2"/>
      </form>
    </div>
  </div>
</template>

<script>
import { required, sameAs, minLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import profileResource from "@http/ProfileResource";
import toastParamBuilder from "@/builders/ToastParamBuilder";

export default {
  setup: () => ({v$: useVuelidate()}),

  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        phone: '',
        is_notification: null,
      },
      submitted: false,
    }
  },

  mounted() {
    this.getUserData();
  },

  methods: {
    getUserData() {
      profileResource.getPersonalData()
        .then(({data}) => {
          this.form.first_name = data.first_name;
          this.form.last_name = data.last_name;
          this.form.email = data.email;
          this.form.phone = data.phone;
          this.form.is_notification = !!data.is_notification;
        });
    },

    handleSubmit(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      profileResource.updatePersonalData(this.form)
        .then(({data}) => {
          this.$toast.add(toastParamBuilder.success(data));
        })
        .catch((error) => {
          this.$toast.add(toastParamBuilder.error(error.response?.data?.message));
        });
    },
  },

  validations() {
    return {
      form: {
        first_name: { required },
        last_name: { required },
        password: { minLength: minLength(8) },
        password_confirmation: { sameAsPassword: sameAs(this.form.password) },
        phone: { required },
        is_notification: { required },
      },
    };
  },
}
</script>

<style scoped>
  .lpmb-2 {
    margin-bottom: 2.8rem;
  }

  .disabled {
    cursor: not-allowed;
  }
</style>
