import PersonalPriceResource from '@http/PersonalPriceResource';

const personalPriceResource = new PersonalPriceResource();

export default {
  namespaced: true,

  state: {
    personalPrices: [],
    pagination: {},
  },

  actions: {
    getPersonalPrices({commit}, params) {
      return personalPriceResource.getPersonalPrices(params)
        .then(({data}) => {
          commit('setAll', data.data);
          commit('setPagination', data.meta);

          return Promise.resolve(true);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },

    createPersonalPrice(_, payload) {
      return personalPriceResource.createPersonalPrice(payload)
        .then(response => {
          return Promise.resolve(response);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },

    deletePersonalPrice(_, {id}) {
      return personalPriceResource.deletePersonalPrice(id)
        .then(response => {
          return Promise.resolve(response);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
  },

  mutations: {
    setAll(state, personalPrices) {
      state.personalPrices = personalPrices;
    },

    setPagination(state, page) {
      state.pagination = page;
    },
  },

  getters: {
    personalPrices(state) {
      return state.personalPrices;
    },

    pagination(state) {
      return state.pagination;
    },
  }

};
