import axios from "@/libs/axios";

const endpointPrefix = process.env.VUE_APP_API_PREFIX;

export default {
  all(params) {
    return axios.get(`/${endpointPrefix}/orders`, {params});
  },
  create(params) {
    return axios.post(`/${endpointPrefix}/orders`, params);
  },
  complete(params) {
    return axios.post(`/${endpointPrefix}/orders/complete`, params);
  },
  inProgress(params) {
    return axios.post(`/${endpointPrefix}/orders/in-progress`, params);
  },
  downloadTable(params) {
    return axios.get(`/${endpointPrefix}/orders/download`, { params, responseType: 'blob' });
  }
};
