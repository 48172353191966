import NotificationResource from '@http/NotificationResource';

const notificationResource = new NotificationResource();

export default {
  namespaced: true,

  state: {
    notifications: [],
  },

  mutations: {
    setAll(state, notifications) {
      state.notifications = notifications;
    },
    markAllAsRead(state) {
      state.notifications.forEach(notification => {
        notification.is_read = 1;
      });
    },
  },

  actions: {
    async getNotifications({ commit }) {
      try {
        const { data } = await notificationResource.getNotifications();
        commit('setAll', data.notifications);
        return data.notifications;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    markAllAsRead({ commit }) {
      try {
        notificationResource.markIsReadNotification();
        commit('markAllAsRead');
      } catch (error) {
        return Promise.reject(error);
      }
    }
  },

  getters: {
    notifications(state) {
      return state.notifications || [];
    },
  }
};
