import axiosIns from '@axios';

const endpointPrefix = process.env.VUE_APP_API_PREFIX;

export default class NotificationResource {
  getNotifications() {
    return axiosIns.get(`/${endpointPrefix}/notifications`);
  }
  markIsReadNotification() {
    return axiosIns.post(`/${endpointPrefix}/notifications/mark-read`);
  }
}
