import axiosIns from '@axios';
import errorMessageBuilder from "@/builders/ErrorMessageBuilder";

const endpointPrefix = process.env.VUE_APP_API_PREFIX;

export default class PersonalPriceResource {

  getPersonalPrices(params) {
    return axiosIns.get(`/${endpointPrefix}/personal-prices`, {params});
  }

  createPersonalPrice(data) {
    return axiosIns.post(`/${endpointPrefix}/personal-prices`, data)
      .then(({ data }) => {
        return data;
      })
      .catch(error => {
        return Promise.reject(errorMessageBuilder(error));
      });
  }

  deletePersonalPrice(id) {
    return axiosIns.delete(`/${endpointPrefix}/personal-prices/${id}`)
      .then(({ data }) => {
        return data;
      })
      .catch(error => {
        return Promise.reject(errorMessageBuilder(error));
      });
  }

}
