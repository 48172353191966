import {createStore} from 'vuex';

import auth from '@/store/modules/auth';
import user from './modules/user';
import ApiProvider from './modules/ApiProvider';
import PersonalPrices from './modules/PersonalPrices';
import company from './modules/company';
import tool from './modules/tool';
import order from './modules/order';
import zellePaymentRequest from './modules/zellePaymentRequest';
import transaction from './modules/transaction';
import userSettings from './modules/userSettings';
import notification from './modules/notification';

const store = createStore({
  modules: {
    auth,
    user,
    ApiProvider,
    PersonalPrices,
    company,
    tool,
    order,
    zellePaymentRequest,
    transaction,
    userSettings,
    notification,
  },
  strict: process.env.NODE_ENV !== 'production',
  mutations: {
    initialiseStore(state) {
      if (localStorage.getItem('store')) {
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem('store')))
        );
      }
    }
  },
});

store.subscribe((mutation, state) => {
  localStorage.setItem('store', JSON.stringify(state));
});

export default store;
